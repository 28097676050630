<template>
  <div class="main-container">
    <div class="menu">
      <div
        :class="selectedTabIndex === index ? 'menu-item-selected' : 'menu-item'"
        v-for="(element, index) in tabOptions"
        v-bind:key="index"
        @click="() => switchTab(index)"
      >
        <p>{{ element }}</p>
      </div>
    </div>
    <div class="section-container" v-if="selectedTabIndex === 0">
      <div class="main-heading">
        <h1>{{ "Leaderbots" }}</h1>
        <Button
          :loading="false"
          text="Add"
          type="info"
          :onClick="() => openDeckPage()"
        />
      </div>
      <div class="content-container">
        <div class="row row-header">
          <div
            v-for="column in columns"
            v-bind:key="column"
            class="cell cell-header"
            :style="{
              width: 100 / columns.length + '%',
              maxWidth: 100 / columns.length + '%',
            }"
          >
            {{ $t(column) }}
          </div>
        </div>
        <div
          v-for="(item, index) in leaderbotsArr"
          @click="() => openDeckPage(item.id)"
          v-bind:key="index"
          class="row"
        >
          <div
            class="cell"
            :style="{
              width: 100 / columns.length + '%',
              maxWidth: 100 / columns.length + '%',
            }"
          >
            {{ item.name }}
          </div>
          <div
            class="cell"
            :style="{
              width: 100 / columns.length + '%',
              maxWidth: 100 / columns.length + '%',
            }"
          >
            {{ item.trophy }}
          </div>
          <div
            class="cell"
            :style="{
              width: 100 / columns.length + '%',
              maxWidth: 100 / columns.length + '%',
            }"
          >
            {{ item.profile.winrate + "%" }}
          </div>
          <div
            class="cell"
            :style="{
              width: 100 / columns.length + '%',
              maxWidth: 100 / columns.length + '%',
            }"
          >
            {{ item.attacker_deck_ref?.name ?? "Not selected" }}
          </div>
          <div
            class="cell"
            :style="{
              width: 100 / columns.length + '%',
              maxWidth: 100 / columns.length + '%',
            }"
          >
            {{ item.counter_deck_ref?.name ?? "Not selected" }}
          </div>
          <div
            class="cell"
            :style="{
              width: 100 / columns.length + '%',
              maxWidth: 100 / columns.length + '%',
            }"
          >
            {{ item.status ? "Enabled" : "Disabled" }}
          </div>
        </div>
      </div>
    </div>
    <Configuration v-if="selectedTabIndex === 1" />
  </div>
</template>

<script>
import dispatchMap from "@/constants/dispatchMap";
import { onBeforeMount, onBeforeUnmount, onUpdated, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Button from "../../components/common/Button.vue";
import Configuration from "./components/Configuration.vue"

export default {
  name: "Leaderbots",
  components: {
    Button,
    Configuration
  },
  setup() {
    const tabOptions = ["Leaderbots", "Configuration"];
    const selectedTabIndex = ref(0);

    const router = useRouter();
    const store = useStore();
    const leaderbotsArr = ref([]);
    const usedPages = ["leaderbots"];

    let refreshInterval;
    onBeforeMount(() => {
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => {
          store.dispatch(dispatchStr);
        });
      });

      refreshInterval = setInterval(() => {
        store.dispatch("leaderbots/getLeaderbots");
      }, 5000);
    });

    onUpdated(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => {
      store.dispatch("loader/loadingStatus", true);
      clearInterval(refreshInterval);
    });

    watch(
      () => store.getters["leaderbots/getLeaderbots"],
      (value) => {
        leaderbotsArr.value = [...value].sort((a, b) => b.trophy - a.trophy);
      },
      { immediate: true }
    );

    const openDeckPage = (id) => {
      if (id) router.push({ name: "LeaderbotAdd", query: { id: id } });
      else router.push({ name: "LeaderbotAdd" });
    };

    const switchTab = (index) => {
      selectedTabIndex.value = index;
    };

    return {
      columns: [
        "name",
        "trophy",
        "winrate",
        "attacker_deck_ref",
        "counter_deck_ref",
        "status"
      ],
      leaderbotsArr,
      openDeckPage,
      tabOptions,
      selectedTabIndex,
      switchTab
    };
  },
};
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.section-container {
  padding: 10px 30px;
}

.content-container {
  width: 100%;
}

.row {
  flex-grow: 1;
  width: 100%;
  display: inline-flex;
  max-height: 80px;
  overflow: hidden;
  align-items: center;
}

.row:hover {
  background-color: aliceblue;
  cursor: pointer;
}

.cell.cell-header {
  font-size: 1.15rem;
  font-weight: 600;
  border-bottom: 1px solid gray;
}

.cell {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px 0;
}

.table-row.header-row {
  font-weight: 600;
  font-size: 1.125rem;
}

.main-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.menu-item {
  cursor: pointer;
  border-bottom: 1px solid grey;
  font-size: 15px;
  padding: 10px 20px;
  min-width: 200px;
}

.menu-item:hover {
  background-color: lavender;
}

.menu-item-selected {
  border-bottom: 1px solid grey;
  font-size: 15px;
  background-color: khaki;
  min-width: 200px;
  padding: 10px 20px;
}
</style>