<template>
  <div class="content-container">
    <div class="container-padding">
      <div class="main-heading">
        <h3>Configuration</h3>
        <Button
          :loading="isLoading"
          text="Save"
          type="success"
          :onClick="() => submitConfiguration()"
        />
      </div>
    </div>
    <div class="condition-container bold-font">
      <div>
        <p>Trophy</p>
      </div>
      <div>
        <p>Win Rate (0 - 100)</p>
      </div>
      <div>
        <p>Win Streak</p>
      </div>
      <div>
        <p>Probability (0 - 100)</p>
      </div>
      <div>
        <Button
          size="sm"
          :loading="false"
          text="Add"
          type="success"
          :onClick="() => addConfiguration()"
        />
      </div>
    </div>
    <div
      class="condition-container"
      v-for="(item, index) in configurationItems"
      v-bind:key="index"
    >
      <div>
        <input v-model.number="item.trophy" />
      </div>
      <div>
        <input v-model.number="item.win_rate" />
      </div>
      <div>
        <input v-model.number="item.win_streak" />
      </div>
      <div>
        <input v-model.number="item.probability" />
      </div>
      <div>
        <Button
          size="sm"
          :loading="false"
          text="Remove"
          type="error"
          :onClick="() => deleteConfiguration(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import Button from "../../../components/common/Button.vue";

export default {
  name: "LeaderbotsConfiguration",
  components: {
    Button,
  },
  setup() {
    const store = useStore();
    const configurationItems = ref([]);
    const maxAllowedMatchCount = ref(0);
    const isLoading = ref(false)

    onMounted(() => {
      getConfiguration();
    });

    watch(
      () => store.getters["serverConfig/getLeaderBotConfig"],
      (value) => {
        if (!value) return;
        const data = JSON.parse(JSON.stringify(value));
        configurationItems.value = data.conditions;
        maxAllowedMatchCount.value = data.max_allowed_match_per_day;
      },
      { immediate: true }
    );

    const getConfiguration = () => {};

    const addConfiguration = () => {
      configurationItems.value.push({
        trophy: 0,
        win_rate: 0,
        win_streak: 0,
        probability: 0,
      });
    };

    const deleteConfiguration = (index) => {
      configurationItems.value.splice(index, 1);
    };

    const submitConfiguration = () => {
      isLoading.value = true;
      const data = {
        max_allowed_match_per_day: maxAllowedMatchCount.value,
        conditions: configurationItems.value
      }
      store.dispatch("serverConfig/updateLeaderBotConfig", data).then(() => {
        isLoading.value = false;
      });
    };

    return {
      configurationItems,
      addConfiguration,
      deleteConfiguration,
      submitConfiguration,
      isLoading
    };
  },
};
</script>

<style scoped>
.content-container {
  width: 100%;
}

.container-padding {
  padding: 30px;
}

.main-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.condition-container {
  margin: 15px;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: stretch;
  align-items: stretch;
  border-bottom: 1px solid grey;
}

.bold-font {
  font-weight: bold;
}
</style>